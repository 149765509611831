import React, { Fragment } from 'react'
import {
  MenuHeader,
  MenuInline,
  MenuWrapper,
  MenuSubWrapper,
  MenuWrapperRight,
  MenuFirst,
  MenuFirstList,
  MenuSecond,
  MenuSecondList,
  MenuThird,
  MenuThirdList,
  MenuHeaderMobile,
} from './styles'
import { white, blue, black, breakpoints, background } from '../../assets/styles/styles'
import {
  Text,
  Absolute,
  Flex,
  Icon,
  Relative,
  Separator,
  Overlay,
} from '../../assets/styles/components'
import Masonry from 'react-masonry-component'
import { withContext } from '../../utils/helper'
import MediaQuery from 'react-responsive'

const masonryOptions = {
    transitionDuration: 0,
}
  
const imagesLoadedOptions = { background: '.my-bg-image-el' }

function SubMenu({categories, subcategories, subthirdcategories, secondopen, open, third, changeState, catdigital, subcategory, open1, context, location, secondopen1, capitalizeWords }) {
  return (
        <>
          {open ? (
            <div className='submenu-list'>
            <MenuSubWrapper
              onMouseLeave={() => {
                changeState('third', '')
                changeState('subcategory', [])
                changeState('open', false)
              }}
            //   ref={(ref) => (this.wrapper = ref)}
            >
              <MenuSecond
                onMouseEnter={() => {
                  changeState('secondopen', true)
                }}
                onMouseLeave={() => {
                  changeState('secondopen', false)
                }}
              >
                {categories.map((g, i) => (
                  <MenuSecondList
                    key={i}
                    onMouseEnter={() => {
                      changeState('third', g)
                      changeState('subcategories', g.child)
                    }}
                    onClick={() => {
                      changeState('open', false)
                     
                    }}
                    className={g.name === third.name ? 'active' : ''}
                  >
                    <a href={`/subcategory/${g.slug}`}>
                      <Flex justifyContent="space-between" alignItems="center">
                        <Text fontSize="12px" isTitle="true">
                          {g.name.toUpperCase()}
                        </Text>
                        
                      </Flex>
                      
                    </a>
                  </MenuSecondList>
                ))}
                <MenuSecondList
                  onClick={() => {
                    if (location.pathname == '/brand') {
                      changeState('open', false)
                      return false
                    }
                    context.state.history.push('/brand')
                  }}
                  onMouseEnter={() => {
                    changeState('third', '')
                    changeState('subcategories', [])
                  }}
                >
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text fontSize="12px" isTitle="true">
                      BRAND
                    </Text>
                    <Icon
                      className="fa fa-chevron-right"
                      style={{ fontSize: 12, color: white }}
                    />
                  </Flex>
                  
                </MenuSecondList>
              </MenuSecond>
              {subcategories.length > 0 && (
              <div className='row' style={{ width: '55%', display: 'inline-block', backgroundColor: '#fff', marginLeft: '0px', boxShadow: '0px 1px 4px 0px rgba(155, 155, 155, 0.3)', minHeight: '90%', overflow:'auto'}}>
              {subcategories.length > 0 ? (
                subcategories.map((g, i) => (
                  
                    <div className='col-md-3 m-0 p-0 mb-0' style={{ float: 'left' }}>
                    <MenuThird
                      style={{ marginBottom: '0px' }}
                      key={i}
                      onMouseEnter={() => {
                        changeState('secondopen', true)
                      }}
                    >
                      <a href={`/subcategory/${g.slug}`}><Text
                        margin="0 0 5px"
                        isTitle="true"
                        fontWeight="bold"
                        fontSize="10px"
                        hoverStyle={{ color: blue }}
                      >
                        {g.name.toUpperCase()}
                      </Text></a>
                      <Masonry
                        className={'masonry'} // default ''
                        elementType={'div'} // default 'div'
                        options={masonryOptions} // default {}
                        disableImagesLoaded={false} // default false
                        updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
                        imagesLoadedOptions={imagesLoadedOptions} // default {}
                      >
                        {g.subchild.length > 0 ? (
                          g.subchild.map((gsub, i) => (
                        <MenuThirdList key={i}>
                          <a href={`/subcategory/${gsub.slug}`}>
                            <Text
                              fontSize="12px"
                              // fontWeight="bold"
                              margin="0"
                              cursor="pointer"
                              hoverStyle={{ color: blue }}
                              isTitle="true"
                              onClick={() => {
                               
                                changeState('open', false)
                              }}
                            >
                              {gsub.name}
                            </Text>
                          </a>
                         
                        </MenuThirdList>
                      ))
                    ) : (
                      <Text fontSize="12px" margin="0 0 5px" isTitle="true">
                        No category found
                      </Text>
                    )}
                      </Masonry>
                    </MenuThird>
                    </div>

                  ))
              ) : null}
              </div>)}
            </MenuSubWrapper></div>
          ) : null}

          </>
  )
}

export default withContext(SubMenu)
