
import React from 'react'
import { Row, Col } from '../../../components/Bootstrap/Bootstrap'
import {
  Text,
  Relative,
  Absolute,
  Icon,
  Flex,
} from '../../../assets/styles/components'

const ProductCategoryList = ({
    data,
}) => {
  return (
    
    <Row margin="0 -15px 10px">
      <Col md={12} style={{ marginBottom: '20px' }}>
        { data.length > 0 && data.map((g, i) => (
        <>
        <Text margin="0 0 10px" fontSize="15px" fontWeight="bold">
          {g.name}
        </Text>
        <Relative>
            
            <ul className="product-category-list">
                { g.child.length > 0 && g.child.map((item, index) => (
                <li key={index}>
                    <a href="/category/1">{item.name} <span className="fa fa-chevron-right"></span></a>
                    <ul className="sub-category-list d-none">
                        { item.subchild.length > 0 && item.subchild.map((item1, index1) => (
                            <li key={index1}>
                             <a href="/">{item1.name}</a>
                            </li>
                        ))}
                    </ul>
                </li>
                ))}
            </ul>
        </Relative></>
        ))}
      </Col>
    
    </Row>
  )
}

export default ProductCategoryList
