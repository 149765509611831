import React, { Fragment } from 'react'
import Header from '../../components/Header/Header'
import Copyright from '../../components/Copyright/Copyright'
import { withContext } from '../../utils/helper'
import { Container, Row, Col } from '../../components/Bootstrap/Bootstrap'
import dummy from '../../utils/dummy'
import { Relative, FlexCell, PageWrapper } from '../../assets/styles/components'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import CartLeft from './components/CartLeft'
import CartRight from './components/CartRight'
import ModalPayment from './components/ModalPayment'
import ajax from './ajax'
import ajaxAddress from '../Profile/ajax'
import ajaxProduct from '../Product/ajax'
import ModalAddAddress from '../Profile/components/ModalAddAddress'
import ModalChooseAddress from './components/ModalChooseAddress'
import { Redirect } from 'react-router-dom'
import Footer from '../../components/Footer/Footer'
import { calculateDiscount, formatMoney } from '../../utils/format'

class Cart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      errordata: false,
      data: [],
      dataProduct: [],
      checkall: true,
      courier: '',
      courierlist: [],
      couriertype: '',
      couriertypelist: [],
      promo: '',
      promotext: '',
      modalPayment: false,
      paymentUrl: '',
      totalprice: 0,
      discount: 0,
      productdiscount: 0,

      loadingAddress: false,
      addresslist: [],
      address: {},
      modalAddAddress: false,

      loadingModal: false,
      errorModal: '',
      modalChooseAddress: false,

      loadingCourier: false,
      errorCourier: '',

      redirect: '',
    }
    this.fetchData = this.fetchData.bind(this)
    this.fetchAddress = this.fetchAddress.bind(this)
    this.delete = this.delete.bind(this)
    this.submit = this.submit.bind(this)
    this.addAddress = this.addAddress.bind(this)
    this.checkVoucher = this.checkVoucher.bind(this)
    this.checkDelivery = this.checkDelivery.bind(this)
    this.updateQty = this.updateQty.bind(this)
    this.fetchCourier = this.fetchCourier.bind(this)
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    this.props.context.update('history', this.props.history)
    // setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
    this.fetchData()
    this.fetchAddress()
    this.fetchCourier()
  }
  componentWillUnmount() {
    this.updateQty()
    this.props.context.update('showDandanLoader', true)
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.data != this.state.data ||
      prevState.promo != this.state.promo
    ) {
      let totalprice = 0
      let discount = 0
      let productdiscount = 0
      this.state.data.map(g => {
        if (g.checked) {
          totalprice += parseInt(g.price) * parseInt(g.qty)
          discount +=
            calculateDiscount(g.price, g.discount_type, g.current_discount)
              .discount * parseInt(g.qty)
        }
        return g
      })
      productdiscount = discount
      if (this.state.promo) {
        discount += parseInt(
          ((totalprice - discount) *
            parseInt(this.state.promo.discount_percentage || 0)) /
            100 +
            parseInt(this.state.promo.discount_amount || 0) +
            parseInt(this.state.promo.discount_delivery || 0),
        )
      }
      totalprice -= discount
      // if (totalprice < 0 || !totalprice) totalprice = 0
      // if (discount < 0 || !discount) discount = 0
      this.setState({ totalprice, discount, productdiscount })
    }
    if (
      prevState.courier != this.state.courier ||
      prevState.address != this.state.address
    ) {
      this.checkDelivery()
    }
    if (prevState.couriertype != this.state.couriertype && this.state.promo)
      this.checkVoucher()
  }
  async fetchData() {
    const { token } = this.props.context.state
    this.setState({ loading: true, errordata: false })
    let res = await ajax.getData(token)
    if (!res.success) {
      this.setState({ loading: false, errordata: true })
      return false
    }
    let data = res.data
    let totalprice = 0
    data = data.map(g => {
      totalprice += parseInt(g.price) * parseInt(g.qty)
      g.oldqty = g.qty
      g.checked = true
      return g
    })
    let dataProduct=[{
      "id" : "431133",
      "name" : "Hanasui Collagen Water Sunscreen SPF50 30ml",
      "image" : "https://dandan-assets.s3-ap-southeast-1.amazonaws.com/public/product/PBiog5j3FRx8KQIWh4DDRdsneqmWr4VDj0FugYo4.jpeg",
      "price" : "39.000",
      "slug" : "hanasui-collagen-water-sunscreen-spf50-30ml-3259"
    },
    {
      "id" : "432691",
      "name" : "Implora 24K Gold Serum 20ml",
      "image" : "https://dandan-assets.s3-ap-southeast-1.amazonaws.com/public/product/432691%202.jpg",
      "price" : "22.478",
      "slug" : "implora-24k-gold-serum-20ml-4342"
    },
    {
      "id" : "430999",
      "name" : "Implora Cheek Blossom Blush On Coral Peach 3.5g",
      "image" : "https://dandan-assets.s3-ap-southeast-1.amazonaws.com/public/product/430999%202.jpg",
      "price" : "20.970",
      "slug" : "implora-cheek-blossom-blush-on-coral-peach-35g-4344"
      
    },
    {
      "id" : "431019",
      "name" : "Implora Cheek & Lip Tint 04 Red Wine 5.5gr",
      "image" : "https://dandan-assets.s3-ap-southeast-1.amazonaws.com/public/product/431015%202.jpg",
      "price" : "17.212",
      "slug" : "implora-cheek-lip-tint-01-vampire-blood-55gr-4348"
    }];
    this.setState({
      data,
      dataProduct,
      totalprice,
      loading: false,
    })
    setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
  }
  async fetchAddress() {
    const { token } = this.props.context.state
    this.setState({ loadingAddress: true, errordataAddress: false })
    let res = await ajaxAddress.getAddress(token)
    if (!res.success) {
      this.setState({ loadingAddress: false, errordataAddress: true })
      return false
    }
    let addresslist = res.data
    let address = {}
    if (addresslist.length > 0) {
      address = addresslist[addresslist.length - 1]
    }
    this.setState({
      addresslist,
      address,
      loadingAddress: false,
      errordataAddress: false,
    })
  }
  changeState = (key, value) => {
    this.setState({ [key]: value })
  }

  checkItem = index => {
    let data = [...this.state.data]
    data[index].checked = !data[index].checked
    this.setState({ data })
  }
  checkAllItem = () => {
    let data = this.state.data
    data = data.map(g => {
      g.checked = !this.state.checkall
      return g
    })
    this.setState({ data, checkall: !this.state.checkall })
  }
  changeQuantity = (index, qty) => {
    let data = [...this.state.data]
    data[index].oldqty = data[index].qty
    data[index].qty = qty
    this.setState({ data })
  }
  async addAddress(
    e,
    name,
    receiver_name,
    receiver_phone,
    address,
    locationid,
  ) {
    e.preventDefault()
    this.setState({ loadingModal: true, errorModal: '' })
    const { token } = this.props.context.state
    let res = await ajaxAddress.addAddress(
      name,
      receiver_name,
      receiver_phone,
      address,
      locationid,
      token,
    )
    if (!res.success) {
      this.setState({ loadingModal: false, errorModal: res.message })
      return false
    }

    this.setState({
      loadingModal: false,
      modalAddAddress: false,
    })
    this.props.context.update('success', res.message)
    this.fetchAddress()
  }
  async updateQty() {
    // this.setState({ loading: true, error: '' })
    const { token } = this.props.context.state
    const { data } = this.state
    let res
    for (let i = 0; i < data.length; i++) {
      if (data[i].oldqty != data[i].qty)
        res = await ajax.updateCart(data[i].cart_id, data[i].qty, token)
    }
    this.props.context.update('refetchCart', true)
    // if (!res.success) {
    //   this.setState({ loading: false, error: res.message })
    //   return false
    // }
    // this.fetchData()
    // this.props.context.update('refetchCart', true)
  }
  async delete(id) {
    this.setState({ loading: true, error: '' })
    const { token } = this.props.context.state
    let res = await ajax.deleteCart(id, token)
    if (!res.success) {
      this.setState({ loading: false, error: res.message })
      return false
    }
    this.fetchData()
    this.props.context.update('refetchCart', true)
  }

  async fetchCourier() {
    this.setState({ loadingCourier: true, errorCourier: '' })
    const { token } = this.props.context.state
    let res = await ajax.getCourier(token)
    if (!res.success) {
      this.setState({ loadingCourier: false, errorCourier: res.message })
      return false
    }
    let courierlist = res.data
    let courier = ''
    // if (courierlist.length > 0) courier = courierlist[0]
    this.setState({ courier, courierlist, loadingCourier: false })
  }

  async checkDelivery() {
    this.setState({ loadingCourierType: true, errorCourierType: '' })
    const { token } = this.props.context.state
    const { address, courier } = this.state
    let res = await ajax.checkDelivery(
      address.customer_address_id,
      courier ? courier.courier_id : '',
      token,
    )
    if (!res.success) {
      this.setState({
        loadingCourierType: false,
        errorCourierType: res.message,
      })
      return false
    }
    let couriertypelist = []
    let couriertype = ''
    res.data.map(g => {
      let obj = {
        ...g,
        name: `${g.service_name} (${g.etd}) Rp. ${formatMoney(g.price)}`,
        fullname: `${courier.name} - ${g.service_name} (${
          g.etd
        }) Rp. ${formatMoney(g.price)}`,
        price: parseInt(g.price),
      }
      if (g.price != '-') couriertypelist.push(obj)
      return g
    })
    if (couriertypelist.length > 0) couriertype = couriertypelist[0]
    this.setState({ couriertype, couriertypelist, loadingCourierType: false })
  }
  async checkVoucher() {
    const { token } = this.props.context.state
    const { promotext, couriertype, totalprice } = this.state
    if (!promotext) {
      this.setState({ errorPromo: 'Mohon isi kode voucher' })
      return false
    }
    this.setState({ loadingPromo: true, errorPromo: '' })
    let res = await ajax.checkVoucher(
      promotext,
      couriertype.courier_service_id,
      totalprice,
      token,
    )
    if (!res.success) {
      this.setState({ loadingPromo: false, errorPromo: res.message, promo: '' })
      return false
    }
    this.setState({ promo: res.data.voucher, loadingPromo: false })
  }
  async add(id, data) {
    const { token } = this.props.context.state
    const { qty } = this.state
    if (!token) {
      this.props.context.update('showLogin', true)
      return false
    }
    this.props.context.update('showLoading', true)

    let res = await ajaxProduct.addToCart(id, 1, token)

    if (!res.success) {
      this.props.context.update('showLoading', false)
      this.props.context.update('error', res.message)
      return false
    }
    this.props.context.update('showLoading', false)
    this.props.context.update('successCart', data)
    this.props.context.update('refetchCart', true)
  }

  async submit() {
    this.props.context.update('showLoading', true)
    const { token } = this.props.context.state
    const { address, couriertype, promo } = this.state
    let res = await ajax.createTransaction(
      address.customer_address_id,
      couriertype.courier_service_id,
      promo ? promo.voucher_id : '',
      token,
    )
    if (!res.success) {
      this.props.context.update('showLoading', false)
      this.props.context.update('error', res.message)
      return false
    }
    let res2 = await ajax.snapToken(res.data.transaction_id, token)
    if (!res2.success) {
      this.props.context.update('showLoading', false)
      this.props.context.update('error', res2.message)
      return false
    }
    this.props.context.update('showLoading', false)

    const snap = window.snap
    snap.pay(res2.data.snaptoken)

    this.fetchData()
    this.props.context.update('refetchCart', true)
    this.props.context.update('refetchOrder', true)
  }
  render() {
    const {
      loading,
      errordata,
      data,
      dataProduct,
      checkall,
      courier,
      courierlist,
      promo,
      promotext,
      loadingPromo,
      errorPromo,

      totalprice,
      discount,
      modalPayment,
      paymentUrl,
      loadingAddress,
      addresslist,
      address,
      modalAddAddress,
      loadingModal,
      errorModal,
      modalChooseAddress,
      loadingCourier,
      errorCourier,
      redirect,
      couriertypelist,
      couriertype,
      loadingCourierType,
      errorCourierType,
      productdiscount,
    } = this.state
    if (redirect) return <Redirect to={redirect} push />
    return (
      <Fragment>
        <Header match={this.props.match} location={this.props.location} />

        <PageWrapper promo={this.props.context.state.promo.content}>
          <FlexCell>
            <Relative padding="1em 2em" mobileStyle={{ padding: '15px 20px' }}>
              <Breadcrumb text={`Home > Shopping Cart`} margin="0 0 10px" />
              <Container>
                <Row margin="0 -15px 30px">
                  <CartLeft
                    checkall={checkall}
                    checkAllItem={this.checkAllItem}
                    data={data}
                    loading={loading}
                    checkItem={this.checkItem}
                    changeState={this.changeState}
                    changeQuantity={this.changeQuantity}
                    deleteCart={this.delete}
                  />
                  <CartRight
                    data={data}
                    dataProduct={dataProduct}
                    loading={loadingAddress}
                    address={address}
                    changeState={this.changeState}
                    courier={courier}
                    courierlist={courierlist}
                    couriertype={couriertype}
                    couriertypelist={couriertypelist}
                    promo={promo}
                    promocode={promotext}
                    loadingPromo={loadingPromo}
                    errorPromo={errorPromo}
                    totalprice={totalprice}
                    submit={this.submit}
                    checkVoucher={this.checkVoucher}
                    loadingCourier={loadingCourier}
                    errorCourier={errorCourier}
                    checkDelivery={this.checkDelivery}
                    updateQty={this.updateQty}
                    discount={discount}
                    loadingCourierType={loadingCourierType}
                    errorCourierType={errorCourierType}
                    productdiscount={productdiscount}
                    add={this.add}
                  />
                </Row>
              </Container>
            </Relative>
          </FlexCell>

          <FlexCell>
            <Footer location={this.props.location} />
            <Copyright />
          </FlexCell>
        </PageWrapper>
        {/* <ModalPayment
          open={modalPayment}
          url={paymentUrl}
          close={() => this.changeState('modalPayment', false)}
        /> */}
        <ModalAddAddress
          open={modalAddAddress}
          close={() => this.changeState('modalAddAddress', false)}
          type="add"
          changeState={this.changeState}
          loading={loadingModal}
          error={errorModal}
          add={this.addAddress}
        />
        <ModalChooseAddress
          open={modalChooseAddress}
          data={addresslist}
          changeState={this.changeState}
          activeData={address}
          close={() => this.changeState('modalChooseAddress', false)}
          submit={address => this.changeState('address', address)}
        />
      </Fragment>
    )
  }
}

export default withContext(Cart)
