import React, { Fragment, useContext } from 'react'
import Slider from 'react-slick'
import showDandanLoaderlider from 'react-slick'
import { NextArrow, PrevArrow } from '../../../utils/helper'
import { useMediaQuery } from 'react-responsive'
import ProductList from '../../Product/components/ProductList'
import { Row, Col } from '../../../components/Bootstrap/Bootstrap'
import {
  Text,
  Flex,
  Separator,
  FlexCell,
  Relative,
  Absolute,
  Icon,
} from '../../../assets/styles/components'
import { Wrapper } from '../styles'
import Button from '../../../components/Button/Button'
import Select from '../../../components/Select/Select'
import { formatMoney } from '../../../utils/format'
import InputOutlined from '../../../components/Input/InputOutlined'
import TebusMurah from '../../Product/components/TebusMurah'
import Spinner from '../../../components/Loader/Spinner'
import { blue, red, black } from '../../../assets/styles/styles'
import ErrorText from '../../../components/Error/ErrorText'
import { AppContext } from '../../../context/AppContext'
import Tooltip from 'rc-tooltip'

const settings = {
  dots: false,
  // infinite: false,
  speed: 500,
  slidesToShow: 3,  
  slidesToScroll: 1,
  // arrows: false,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
}


const CartRight = ({
  data,
  dataProduct,
  address,
  totalprice,
  changeState,
  courier,
  courierlist,
  couriertype,
  couriertypelist,
  promo,
  promotext,
  loading,
  error,
  loadingPromo,
  errorPromo,
  submit,
  checkVoucher,
  loadingCourier,
  errorCourier,
  updateQty,
  discount,
  loadingCourierType,
  errorCourierType,
  productdiscount,
  add
}) => {
  const { update } = useContext(AppContext)
  const isMobile = useMediaQuery({ maxWidth: 200 })
  return (
    <Col md={4} padding="0">
      <Wrapper margin="0 0 0 15px" mobileStyle={{ margin: '15px 0 0' }}>
        {/* <Text fontSize="16px" fontWeight="bold">
          Tebus Murah
        </Text>

        <Separator margin="10px 0 15px" />

        <div className='product-group' style={{paddingLeft:'10px', paddingRight:'10px'}}>
          <Slider className='cart_slider' style={{}}
            {...settings}
            infinite={isMobile ? dataProduct.length > 2 : dataProduct.length > 2}
          >
            {dataProduct &&
              dataProduct.map((g, i) => (
                <div key={i}>
                  <div style={{ padding: '5px 5px'}}>
                    <TebusMurah data={g} add={add}/>
                  </div>
                </div>
              ))}
          </Slider>
        </div>
        <Separator margin="10px 0 15px" /> */}

        <Text fontSize="16px" fontWeight="bold">
          Cart Summary
        </Text>

        <Separator margin="10px 0 15px" />

        {loading ? (
          <Flex justifyContent="center">
            <Spinner className="fa-2x" />
          </Flex>
        ) : (
          <Fragment>
            <Flex alignItems="flex-start">
              <FlexCell flex={0.3} style={{ maxWidth: '30%' }}>
                <Text fontSize="15px">Send to</Text>
              </FlexCell>
              <FlexCell flex={0.7} style={{ maxWidth: '70%' }}>
                {address.customer_address_id ? (
                  <Fragment>
                    <Text fontWeight="bold" fontSize="15px">
                      {`${address.name} `}
                      <span
                        style={{
                          fontSize: 13,
                          cursor: 'pointer',
                          fontWeight: 'normal',
                          textDecoration: 'underline',
                          marginLeft: 5,
                        }}
                        onClick={() => changeState('modalChooseAddress', true)}
                      >
                        change
                      </span>
                    </Text>
                    <Text fontSize="13px" margin="3px 0 0">
                      {address.address}
                    </Text>
                  </Fragment>
                ) : (
                  <Text fontWeight="bold" fontSize="15px">
                    No address yet{' '}
                    <span
                      style={{
                        fontSize: 13,
                        cursor: 'pointer',
                        fontWeight: 'normal',
                        textDecoration: 'underline',
                        marginLeft: 5,
                      }}
                      onClick={() => changeState('modalAddAddress', true)}
                    >
                      add
                    </span>
                  </Text>
                )}
              </FlexCell>
            </Flex>

            <Flex margin="15px 0 0" alignItems="flex-start">
              <FlexCell flex={0.3} style={{ maxWidth: '30%' }}>
                <Text fontSize="14px">Delivery</Text>
              </FlexCell>
              <FlexCell flex={0.7} style={{ maxWidth: '70%' }}>
                {loadingCourier ? (
                  <Spinner />
                ) : courierlist.length > 0 ? (
                  <Select
                    placeholder="Choose Courier"
                    value={courier}
                    option={courierlist}
                    optionKey="name"
                    onChange={changeState}
                    stateKey="courier"
                    margin="0"
                  />
                ) : (
                  <Text fontWeight="bold">Courier not available</Text>
                )}
                {courier.courier_id ? (
                  <Fragment>
                    {loadingCourierType ? (
                      <Flex justifyContent="center" margin="15px 0 0">
                        <Spinner />
                      </Flex>
                    ) : courierlist.length > 0 ? (
                      <Select
                        placeholder="Choose Type"
                        value={couriertype}
                        option={couriertypelist}
                        optionKey="name"
                        onChange={changeState}
                        stateKey="couriertype"
                        margin="15px 0 0"
                      />
                    ) : (
                      <Text fontWeight="bold">Courier not available</Text>
                    )}
                  </Fragment>
                ) : null}
              </FlexCell>
            </Flex>
          </Fragment>
        )}

        <Separator margin="15px 0" />

        <Flex margin="0 0 5px" justifyContent="space-between">
          <Text fontSize="14px">Delivery Price</Text>
          <Text fontSize="15px" fontWeight="bold" color="#777">
            Rp {formatMoney(couriertype.price || 0)}
          </Text>
        </Flex>

        <Flex
          margin={discount > 0 ? '0 0 5px' : '0 0 10px'}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <FlexCell flex={0.5}>
            <Text fontSize="14px">Total Price</Text>
          </FlexCell>
          <FlexCell flex={0.5}>
            <Text
              fontSize="15px"
              fontWeight="bold"
              color="#777"
              textAlign="right"
            >
              Rp {formatMoney(totalprice + discount)}
            </Text>
            {discount > 0 ? null : <Separator margin="10px 0 0" />}
          </FlexCell>
        </Flex>

        {discount > 0 ? (
          <Flex margin="0 0 10px" justifyContent="space-between">
            <FlexCell flex={0.5}>
              <Text fontSize="14px">Discount</Text>
            </FlexCell>
            <FlexCell flex={0.5}>
              <Text
                fontSize="15px"
                fontWeight="bold"
                color={red}
                textAlign="right"
              >
                - Rp {formatMoney(discount)}
                <Tooltip
                  placement="top"
                  trigger={['hover']}
                  overlay={
                    <div>
                      <div>
                        Product Discount : Rp {formatMoney(productdiscount)}
                      </div>
                      {promo.discount_delivery ? (
                        <div>
                          Delivery Discount : Rp{' '}
                          {formatMoney(promo.discount_delivery || 0)}
                        </div>
                      ) : null}
                      {promo.discount_percentage > 0 ||
                      promo.discount_amount > 0 ? (
                        <div>
                          Voucher Discount : Rp{' '}
                          {formatMoney(
                            parseInt(promo.discount_percentage || 0) / 100 +
                              parseInt(promo.discount_amount || 0),
                          )}
                        </div>
                      ) : null}
                    </div>
                  }
                >
                  <Icon
                    className="fa fa-info-circle"
                    cursor="pointer"
                    style={{ marginLeft: 5, color: black }}
                  />
                </Tooltip>
              </Text>
              <Separator margin="10px 0 0" />
            </FlexCell>
          </Flex>
        ) : null}

        <Flex margin="0 0 10px" justifyContent="space-between">
          <Text fontSize="14px">Total Price</Text>
          <Text fontSize="15px" fontWeight="bold">
            Rp {formatMoney(totalprice + (couriertype.price || 0))}
          </Text>
        </Flex>

        <Flex margin="15px 0 10px" alignItems="center">
          <FlexCell flex={0.3}>
            <Text fontSize="14px">Promo Code</Text>
          </FlexCell>
          <FlexCell flex={0.7}>
            {promo ? (
              <Text fontWeight="bold" fontSize="14px">
                {`${promo.promo_code} `}
                <span
                  style={{
                    fontSize: 13,
                    cursor: 'pointer',
                    fontWeight: 'normal',
                    textDecoration: 'underline',
                    marginLeft: 5,
                  }}
                  onClick={() => changeState('promo', '')}
                >
                  change
                </span>
              </Text>
            ) : (
              <Relative>
                <InputOutlined
                  placeholder="Insert promo code"
                  value={promotext}
                  onChange={e => changeState('promotext', e.target.value)}
                  margin="0"
                  padding="8px 74px 8px 12px"
                  onKeyPress={e => {
                    if (e.key === 'Enter') checkVoucher()
                  }}
                />
                <Absolute top="50%" right="10px" transform="translateY(-50%)">
                  {loadingPromo ? (
                    <Spinner />
                  ) : (
                    <Text cursor="pointer" color={blue} onClick={checkVoucher}>
                      Use
                    </Text>
                  )}
                </Absolute>
                {errorPromo ? (
                  <Absolute bottom="-20px" right="0">
                    <ErrorText
                      style={{ fontSize: 12, textAlign: 'right', marginTop: 3 }}
                    >
                      {errorPromo}
                    </ErrorText>
                  </Absolute>
                ) : null}
              </Relative>
            )}
          </FlexCell>
        </Flex>

        <Button
          color={couriertype ? 'orange' : 'disabled'}
          padding="10px 20px"
          block
          disabled={!couriertype}
          margin="20px 0 0"
          onClick={() => {
            let finaldata = data.filter(g => g.checked)
            if (finaldata.length === 0) {
              update('error', 'Minimal item is 1')
              return false
            }

            if (!address.customer_address_id) {
              update('error', 'Please choose address')
              return false
            }
            if (!couriertype.courier_service_id) {
              update('error', 'Please choose courier')
              return false
            }
            update('confirmation', {
              text: 'Are you sure with your order?',
              submitText: 'Proceed',
              submit: () => {
                update('confirmation', null)
                updateQty()
                changeState('redirect', {
                  pathname: '/cart/confirmation',
                  state: {
                    data: finaldata,
                    address,
                    courier: couriertype,
                    promo,
                  },
                })
              },
            })
          }}
        >
          Proceed
        </Button>
      </Wrapper>
    </Col>
  )
}

export default CartRight
